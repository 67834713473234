@font-face {
  font-family: 'MTN Brighter Sans';
  src:url('/src/font/MTNBrighterSans-Regular.ttf') format('truetype');
}

.App {
  margin-top: -1.4rem;
  font-family: 'MTN Brighter Sans','Jost', sans-serif;
}

.scrolled-navbar {
  background-color: #00239F !important; /* Change this color */
  transition: background-color 1ms ease-in-out;
}

/* Original navbar styles */
.navbar-nav .nav-link {
  color: #000000 !important;
  text-decoration: none;
}

.navbar-nav .nav-link:hover {
  color: #00239F !important;
  border-bottom: 2px solid #00239F;
}

.navbar-nav .nav-link.active {
  color: #00239F !important;
  border-bottom: 2px solid #00239F;
}


.scrolled-navbar .nav-link {
  color: #ffffff !important; 
}

.scrolled-navbar .nav-link:hover,
.scrolled-navbar .nav-link.active {
  color: #ffffff !important;
  border-bottom: 2px solid #ffffff;
}

.scrolled-navbar .navbar-brand, 
.scrolled-navbar .navbar-toggler-icon {
  color: #ffffff !important;
}



/* Normal button styles */
.btn-outline-primary {
  color: #00239F;
  border-color: #00239F;
}

.btn-outline-primary:hover {
  background-color: #00239F;
  color: #ffffff;
  border-color: #00239F;
}

.btn-outline-dark {
  color: #000;
  border-color: #000;
}

.btn-outline-dark:hover {
  background-color: #000;
  color: #ffffff;
  border-color: #000;
}


.scrolled-navbar .btn-outline-primary {
  color: #ffffff !important;
  border-color: #ffffff !important;
}

.scrolled-navbar .btn-outline-primary:hover {
  background-color: #ffffff !important;
  color: #00239F !important;
  border-color: #ffffff !important;
}

.scrolled-navbar .btn-outline-dark {
  color: #ffffff !important;
  border-color: #ffffff !important;
}

.scrolled-navbar .btn-outline-dark:hover {
  background-color: #ffffff !important;
  color: #00239F !important;
  border-color: #ffffff !important;
}


.navbar-brand .text-dark {
  color: #000000; 
}

.scrolled-navbar .navbar-brand .text-dark {
  color: #ffffff !important;
}


.navbar-toggler-icon {
  background-color: #00239F; 
}

.scrolled-navbar .navbar-toggler {
  background-color: #ffffff; 
}

.scrolled-navbar .navbar-toggler-icon {
  background-color: #00239F !important; 
}



.qr-img{
  width: 200px;
  height: 200px;
}

.qr-img img{
  width: 200px;
  height: 200px;
  object-fit: contain;
}

.service{
  width: 100%;
  height: 380px;
  border-radius: 10px;
}

.service img{
  width: 100%;
  height: 200px;
  object-fit: cover;
  object-position: center;
}


.product {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.product img {
  object-fit: cover;
  height: 200px; /* Adjust as needed */
  width: 100%;
}

.product .card-body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}


.servicep{
  width: 100%;
  height: 350px;
  border-radius: 10px;
}

.servicep img{
  width: 100%;
  height: 200px;
  object-fit: contain;
  object-position: center;
}

.otpLook{
  width:'100%';
  height: 20px;
}
.underlineStyleBase{
  width: 40;
  height: 45;
  border-width: 0;
  border-bottom-width: 2;
  color: '#000'
}


.underlineStyleHighLighted {
  border-color: "#03DAC6"
}

.dashNavItems{
  display: flex;
  align-items: center;
}

.sideNavItems{
  color: #fff;
  cursor: pointer;
  font-weight: bold;
}

.sideNavItems:hover{
  color: #fff;
}

.offcanvas.offcanvas-start {
  width: 300px !important;
  background-color: #00239F !important;
}

.contain{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.containTrans{
  transform: translate(-50%, -50%);
}

.profile-card{
  border-radius: 15px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  width: 550px;
}

.profile-top{
  height: 150px;
  width: 100%;
  border-radius: 15px;
  background-color: #00239F;
}

.profile-pic .propic{
  border: 5px solid #fff;
  margin-top: -60px;
  margin-left: 10px;
}

.main-container{
  margin: 0 10rem;
  padding-bottom: 2rem;
}

.forms-container{
  position: relative;
  z-index: 2; /* Ensure the form is above the overlay */
  padding: 20px;
  margin: 0 10px;
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent background for the form */
  border-radius: 10px;
}

.form-inner .imgLogo img{
  width: 200px;
}

/* new nav bar  */
.bg{
  background-color: #00239F;
}
.spacer{
  margin-top: 6.3rem !important;
}

.spacerOB{
  margin-top: 8.3rem !important;
}

.spacerforms{
  margin-top: 4rem !important;
}
/* new nav bar  */



.terms{
  color: #00239F;
  cursor: pointer;
}

/* tawk */
.tawk-text-center{
  display: none !important;
}



.css-19kzrtu {
  padding: 10px !important;
}

.css-xi606m {
  text-align: left !important;
}

.mobile-head{
  display: flex;
  align-items: center;
  margin-left: 16px !important;
  padding: 10px 0 0 0;
}

.mobile-head img{
  width: 50px;
  height: 50px;
}

.banner{
  margin-top: 11px;
}

.banner-video{
  border-radius: 10px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 100%;
  height: 400px;
}

.banner-video video{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.banner-img{
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.banner-text h1{
  font-size: 3.5rem;
  font-weight: 700;
  color: #000000;
  margin-bottom: 2rem;
}

.banner-img{
  width: 100%;
  height: 500px;
  object-fit: cover;
  object-position: center;
}

.banner-img img{
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.packages h2{
  font-weight: 900;
}

.reviews h2{
  font-weight: 900;
}

.about{
  background-color: #F8F8FF;
  padding: 2rem;
}

.about h2{
  color: #00239F;
  font-weight: 900;
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #00239F;
  --bs-btn-border-color: #00239F;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0b5ed7;
  --bs-btn-hover-border-color: #0a58ca;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0a58ca;
  --bs-btn-active-border-color: #0a53be;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #00239F;
  --bs-btn-disabled-border-color: #00239F;
}

.btn-outline-primary {
  --bs-btn-color: #00239F;
  --bs-btn-border-color: #00239F;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #00239F;
  --bs-btn-hover-border-color: #00239F;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #00239F;
  --bs-btn-active-border-color: #00239F;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #00239F;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #00239F;
  --bs-gradient: none;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: #00239F !important;
}

.pricing h2{
  font-weight: 900;
}

.pricing h3{
  font-weight: 900;
}

.pricing h4{
  font-weight: 900;
}

.watermark{
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 0.05;
  z-index: -1;
}

.watermark2{
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  /* opacity: 0.05; */
  z-index: -1;
}

.footer{
  color: #ffffff;
}

.footer a{
  text-decoration: none;
  color: #ffffff;
}

.map iframe{
  width: 660px; 
  height: 460px;
}

.unique-id{
  font-weight: bold;
  color: red;
}

.sub{
  width: 500px;
  flex: 1;
  margin: auto auto;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 4rem 0;
}

.error-sub {
  width: 500px;
  flex: 1;
  margin: auto auto;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 7.8rem 0;
}

.login-container{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 15px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  padding: 20px;
  background-color: #fff;
}

.signup-container{
  margin: 0 auto;
  border-radius: 15px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  padding: 20px;
  background-color: #fff;
  width: 500px;
}

.form-container{
  width: 500px;
  margin: 0 auto;
}

header{
  width: 500px;
  margin: 0 auto;
  text-align: center;
}

.logo {
  height: 200px;
  width: 200px;
}

.logohead {
  width: 50px;
}

.input-filed{
  margin: 1rem 0;
  width: 100%;
}

.telcos{
  width: 40px;
  height: 40px;
  margin: 0 0.5rem;
  size: 'contain';

}

.all-networks{
  width: 500px;
  margin: 0 auto;
  text-align: center;
  padding-top: 1rem;
}

.all-networks img{
  
  width: auto;
  height: 100px;
  size: 'contain';
}

.PhoneInputCountry{
  border: 1px solid #B9BFC4 !important;
  border-radius: 5px;
  padding: 5px;
}

.PhoneInputCountry select:focus{
  outline-color: #4F9AFF;
}

.phone-input input{
    border: 1px solid #B9BFC4;
    border-radius: 5px;
    padding: 16.5px 14px;
    font-size: 1rem;
}

.phone-input input:focus{
  outline-color: #4F9AFF;
}


.css-dmmspl-MuiFormGroup-root {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: block !important;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.Collapsible__contentInner{
  padding-top: 10px !important;
}

.css-1h7anqn {
  display: flex;
  flex-flow: inherit !important;
}

.unit-price{
  font-weight: bold;
}

.period {
  font-weight: bold;
}

.amount{
  font-weight: bold;
  font-size: 1.5rem;
}

.btns Button{
  margin: 0.5rem 0;
}

/* Mobile  */
@media screen and (max-width: 768px) {
  .main-container {
    margin: 0 1rem;
  }

  .navbar-brand h4 {
    font-size: 1.1rem;
  }

  .profile-card {
    width: 100%;
  }

  .banner-img {
    width: 100%;
    object-fit: cover;
    object-position: center;
  }

  .banner-text h1 {
    font-size: 2rem;
    font-weight: bold;
    color: #000000;
  }

  .banner p,
  .banner .banner-btn,
  .banner-text {
    text-align: center;
  }

  .map iframe {
    width: 100%;
    height: 400px;
  }

  .onboard-btn {
    display: block;
  }

  .form-container,
  .signup-container,
  .login-container {
    width: 100%;
  }

  form,
  .title,
  .subBtn {
    padding: 0 1rem;
  }

  header,
  .sub,
  .error-sub,
  .all-networks {
    width: 100%;
    padding: 0;
  }

  .error-sub {
    padding: 3.8rem 0;
  }
}

/* media query for tablet */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .main-container {
    margin: 0 2rem;
  }

  .navbar-brand h4 {
    font-size: 1rem;
  }

  .profile-card {
    width: 100%;
  }

  .banner-img {
    width: 100%;
    object-fit: cover;
    object-position: center;
  }

  .banner-text h1 {
    font-size: 2.5rem;
    font-weight: bold;
    color: #00239F;
  }

  .banner-text {
    text-align: left;
  }

  .map iframe {
    width: 100%;
    height: 400px;
  }

  .onboard-btn {
    display: none;
  }

  .form-container,
  .signup-container,
  .login-container {
    width: 100%;
  }

  form,
  .title,
  .subBtn {
    padding: 0 1rem;
  }

  header,
  .sub,
  .error-sub,
  .all-networks {
    width: 100%;
    padding: 0;
  }

  .error-sub {
    padding: 12.05rem 0;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1280px) {
  .main-container {
    margin: 0 3rem;
  }

  .navbar-brand h4 {
    font-size: 1rem;
  }

  .banner-img {
    width: 100%;
    object-fit: cover;
    object-position: center;
  }

  .banner-text h1 {
    font-size: 3rem;
    font-weight: bold;
    color: #00239F;
  }

  .banner-text {
    text-align: left;
  }

  .map iframe {
    width: 100%;
    height: 400px;
  }

  .onboard-btn {
    display: none;
  }

  .form-container,
  .signup-container,
  .login-container {
    width: 100%;
  }

  form,
  .title,
  .subBtn {
    padding: 0 1rem;
  }

  header,
  .sub,
  .error-sub,
  .all-networks {
    width: 100%;
    padding: 0;
  }

  .error-sub {
    padding: 16.9rem 0;
  }
}

@media screen and (min-width: 1281px) {
  .main-container {
    margin: 0 10rem;
  }

  .navbar-brand h4 {
    font-size: 1rem;
  }

  .banner-img {
    width: 100%;
    object-fit: cover;
    object-position: center;
  }

  .banner-text h1 {
    font-size: 3.5rem;
    font-weight: bold;
    color: #00239F;
  }

  .banner-text {
    text-align: left;
  }

  .map iframe {
    width: 100%;
    height: 400px;
  }

  .onboard-btn {
    display: none;
  }

  .form-container,
  .signup-container,
  .login-container {
    width: 100%;
  }

  form,
  .title,
  .subBtn {
    padding: 0 1rem;
  }

  header,
  .sub,
  .error-sub,
  .all-networks {
    width: 100%;
    padding: 0;
  }

  .error-sub {
    padding: 20.05rem 0;
  }
}
